import self from '../images/forWhome/self.png'
import relative from '../images/forWhome/relation.png'
import son from '../images/forWhome/son.png'
import daughter from '../images/forWhome/daughter.png'
import brother from '../images/forWhome/brother.png'
import sister from '../images/forWhome/twins.png'
import client from '../images/forWhome/client.png'
import freind from '../images/forWhome/freind.png'



export const forWhome = [
    {
        id:1,
        picture:self,
        forWhome:"Self",
    },
    {
        id:2,
        picture:relative,
        forWhome:"Relative",
    },
    {
        id:3,
        picture:son,
        forWhome:"Son",
    
    },
    {
        id:4,
        picture:daughter,
        forWhome:"Daughter",
    
    },
    {
        id:5,
        picture:brother,
        forWhome:"Brother",
    
    },
    {
        id:6,
        picture:sister,
        forWhome:"Sister",
    
    },
    {
        id:7,
        picture:client,
        forWhome:"Client",
    
    },
    {
        id:8,
        picture:freind,
        forWhome:"Freind",
    
    },
]