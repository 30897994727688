import { Component } from "react";
import img1 from "../../assets/images/blog/author/02.jpg";
import img2 from "../../assets/images/blog/author/03.jpg";
const title = "02 Comment";

let CommentsList = [
  {
    imgUrl: img1,
    imgAlt: "author thumb",
    Name: "Umme Nishat",
    Date: "May 15, 2022 at 12:41 pm",
    Massage:
      "The inner sanctuary, I throw myself down among the tall grass bye the trckli stream and, as I lie close to the earth",
  },
  {
    imgUrl: img2,
    imgAlt: "author thumb",
    Name: "Alexander Brown",
    Date: "May 15, 2022 at 12:41 pm",
    Massage:
      "The inner sanctuary, I throw myself down among the tall grass bye the trckli stream and, as I lie close to the earth",
  },
];

class Comments extends Component {
  render() {
    return (
      <div className="comments">
        <h4 className="comment-title">{title}</h4>
        <ul className="lab-ul comment-list">
          {CommentsList.map((val, i) => (
            <li className="comment" key={i}>
              <div className="com-item">
                <div className="com-thumb">
                  <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                </div>
                <div className="com-content">
                  <div className="com-title">
                    <div className="com-title-meta">
                      <a href="#" className="h7">
                        {val.Name}
                      </a>
                      <span> {val.Date} </span>
                    </div>
                    <span className="reply">
                      <a className="comment-reply-link" href="#">
                        <i className="icofont-reply-all"></i>Reply
                      </a>
                    </span>
                  </div>
                  <p>{val.Massage}</p>
                  <div className="reply-btn"></div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Comments;
