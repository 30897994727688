// images for female--------------------------
import img01 from '../../dating/assets/images/member/home2/project-pic-male/3.png'
import img02 from '../../dating/assets/images/member/home2/project-pic-female/1.png';
import img03 from '../../dating/assets/images/member/home2/project-pic-female/2.png';
import img04 from '../../dating/assets/images/member/home2/project-pic-female/10.png';
import img05 from '../../dating/assets/images/member/home2/project-pic-female/4.png';
import img06 from '../../dating/assets/images/member/home2/project-pic-female/5.png';
import img07 from '../../dating/assets/images/member/home2/project-pic-female/6.png';
import img08 from '../../dating/assets/images/member/home2/project-pic-female/7.png';
import img09 from '../../dating/assets/images/member/home2/project-pic-female/8.png';
import img010 from '../../dating/assets/images/member/home2/project-pic-female/9.png';

// images for male-----------------------------
import img1 from '../../dating/assets/images/member/home2/project-pic-male/1.png';
import img2 from '../../dating/assets/images/member/home2/project-pic-male/2.png';
import img3 from '../../dating/assets/images/member/home2/project-pic-male/3.png';
import img4 from '../../dating/assets/images/member/home2/project-pic-male/4.png';
import img5 from '../../dating/assets/images/member/home2/project-pic-male/5.png';
import img6 from '../../dating/assets/images/member/home2/project-pic-male/6.png';
import img7 from '../../dating/assets/images/member/home2/project-pic-male/7.png';
import img8 from '../../dating/assets/images/member/home2/project-pic-male/8.png';
import img9 from '../../dating/assets/images/member/home2/project-pic-male/9.png';



export const UserData = [
    {
        id: "659bc3546881dc1bef0957a6",
        name: 'John Doe',
        age: "28",
        profession: "Software Developer",
        distance: "40km",
        location: "New York, NY",
        avatar: img1,
    },
    {
        id: "659bc3f26881dc1bef095ae1",
        name: 'Jane Doe',
        age: "23",
        profession: "Software Developer",
        distance: "10km",
        location: "Los Angeles, CA",
        avatar: img02,
    },
    {
        id: "659bc4996881dc1bef0960ac",
        name: 'Mark Johnson',
        age: "32",
        profession: "Graphic Designer",
        distance: "25km",
        location: "Chicago, IL",
        avatar: img2,
    },
    {
        id: "659bc3546881dc1bef0957a6",
        name: 'Emily Smith',
        age: "26",
        profession: "UX Designer",
        distance: "15km",
        location: "Houston, TX",
        avatar: img03,
    },
    {
        id: "659bc9406881dc1bef09d607",
        name: 'Brian Williams',
        age: "30",
        profession: "Data Scientist",
        distance: "18km",
        location: "Phoenix, AZ",
        avatar: img3,
    },
    {
        id: "659bc4996881dc1bef0960ac",
        name: 'Olivia Davis',
        age: "29",
        profession: "Product Manager",
        distance: "22km",
        location: "Philadelphia, PA",
        avatar: img04,
    },
    {
        id: "659bca3f6881dc1bef09df9a",
        name: 'Michael Brown',
        age: "31",
        profession: "Frontend Developer",
        distance: "12km",
        location: "San Antonio, TX",
        avatar: img4,
    },
    {
        id: "659bd54b6881dc1bef0a1be0",
        name: 'Sophia Miller',
        age: "27",
        profession: "Marketing Specialist",
        distance: "35km",
        location: "San Diego, CA",
        avatar: img05,
    },
    {
        id: "659bd61c6881dc1bef0a201f",
        name: 'Daniel Wilson',
        age: "33",
        profession: "Software Engineer",
        distance: "28km",
        location: "Dallas, TX",
        avatar: img5,
    },
    {
        id: "659bc9406881dc1bef09d607",
        name: 'Ava Johnson',
        age: "24",
        profession: "UI Designer",
        distance: "8km",
        location: "San Jose, CA",
        avatar: img06,
    },
    {
        id: "659bd7096881dc1bef0a24fd",
        name: 'William Taylor',
        age: "34",
        profession: "Project Manager",
        distance: "30km",
        location: "Austin, TX",
        avatar: img6,
    },
    {
        id: "659bd7a96881dc1bef0a284b",
        name: 'Emma White',
        age: "25",
        profession: "Content Writer",
        distance: "14km",
        location: "Jacksonville, FL",
        avatar: img07,
    },
    {
        id: "659bd61c6881dc1bef0a201f",
        name: 'Christopher Harris',
        age: "29",
        profession: "Full Stack Developer",
        distance: "20km",
        location: "Indianapolis, IN",
        avatar: img7,
    },
    {
        id: "659bd95e6881dc1bef0a3302",
        name: 'Isabella Thompson',
        age: "31",
        profession: "Business Analyst",
        distance: "32km",
        location: "San Francisco, CA",
        avatar: img08,
    },
    {
        id: "659bd9ce6881dc1bef0a3600",
        name: 'Andrew Martinez',
        age: "26",
        profession: "Network Engineer",
        distance: "11km",
        location: "Columbus, OH",
        avatar: img8,
    },
   
    // Add more users as needed
];

